import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { PageLayout } from 'components';
import styles from './Invalid.module.scss';

const getDescription = (reason: string) => {
    switch (reason) {
        case 'deal_already_claimed':
            return 'Your deal is reserved. Visit the store to redeem it.';
        case 'broken_link':
            return 'The link is broken.';
        case 'unstarted':
            return "The deal hasn't started yet.";
        default:
            return 'Unfortunately, this deal is invalid.';
    }
};

const Invalid: FC = () => {
    const { query } = useRouter();
    const { reason } = query;

    return (
        <PageLayout isFooterVisible={false} isLogoVisible={false}>
            <Head>
                <title>Deal is invalid</title>
            </Head>

            <div className={styles.Content}>
                <section>
                    <h1 className={styles.Title}>
                        <div className={styles.TitleContent}>Deal invalid</div>
                    </h1>
                    <p className={styles.Description}>
                        {getDescription((reason as string) || '')}
                    </p>
                </section>

                <Image
                    src="/images/invalid.svg"
                    layout="intrinsic"
                    width={300}
                    height={300}
                    alt=""
                    aria-hidden="true"
                />
            </div>
        </PageLayout>
    );
};

export default Invalid;
